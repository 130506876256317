import dynamic from "next/dynamic";
import * as React from "react";

import styled from "./core/styled";

const SsrLoading: React.FC<object> = () => {
  return (
    <SsrLoadingBackground>
      <Spinner />
    </SsrLoadingBackground>
  );
};

const Spinner: React.FC = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          x1="8.042%"
          y1="0%"
          x2="65.682%"
          y2="23.865%"
          id="spinnerGradient"
        >
          <stop stopColor="#fff" stopOpacity="0" offset="0%" />
          <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
          <stop stopColor="#fff" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)">
          <path
            d="M36 18c0-9.94-8.06-18-18-18"
            stroke="url(#spinnerGradient)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </path>
          <circle fill="#fff" cx="36" cy="18" r="1">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="0.9s"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </g>
    </svg>
  );
};

const SsrLoadingBackground = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.primary.main};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

function delay(component: React.FC) {
  return () => Promise.resolve(component);
  // return () =>
  //   new Promise<React.FC>(resolve => {
  //     setTimeout(() => resolve(component), 5_000);
  //   });
}

export function withoutSsr(component: React.FC) {
  return dynamic(delay(component), {
    ssr: false,
    loading: SsrLoading
  });
}
